import { AudiencesQuintile, Environment, Geoboundary, Metadata } from '@workspaces/types'
import { AssetDataGeoJsonLayer } from './asset.type'
import { Filter } from './filter.type'
import { FilterAppliedResult } from './plan.type'
import AudiencesQuintileService from '@/services/audiences-quantile.service'

export function isAudiencesQuintileInFilter(filter: Filter): boolean {
  if (!filter.audiencesQuintile) {
    return false
  }
  if (filter.audiencesQuintile.audiences.length === 0) {
    return false
  }

  return true
}

export function isGeoboundaryFilterReadyForAudiencesQuintile(
  filter: Filter,
): boolean {
  const geoboundariesFilter = filter.geoboundaries
  const region2Literal = 'dmas'

  const allGeoboundaries: Geoboundary.AssetFilterGeoboundaryContent[] = Object.values(geoboundariesFilter).flat()

  if (allGeoboundaries.length === 0) {
    return false
  }

  const type2Geoboundaries = allGeoboundaries.filter((geoboundary) =>
    geoboundary.type === region2Literal && !geoboundary.exclude
  )

  if (type2Geoboundaries.length !== allGeoboundaries.length) {
    return false
  }

  const region2Geoboundaries = type2Geoboundaries.filter(
    (geoboundary) => geoboundary.type === region2Literal
  )

  return region2Geoboundaries.length === allGeoboundaries.length
}

export function getGeoboundaryFromFilter(
  filter: Filter,
): AudiencesQuintile.Params {
  const geoboundariesInFilter = filter.geoboundaries
  if (!geoboundariesInFilter) {
    throw new Error('🛑 Geoboundaries not found in filter')
  }

  // Region2 is DMA
  const dmas = geoboundariesInFilter.region_2
  if (dmas && dmas.length) {
    return {
      dma: dmas.map((dma) => dma.name),
    }
  }

  throw new Error('🛑 Geoboundaries not found in filter')
}

export function getParamsFromFilter(
  filter: Filter,
): AudiencesQuintile.RunFilterParams {
  const geoboundary = getGeoboundaryFromFilter(filter)
  const audiencesQuintile = filter.audiencesQuintile
  return {
    geoboundary,
    audiencesQuintile,
  }
}

export async function filterAssetsByAudiencesQuintile(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
): Promise<FilterAppliedResult> {
  if (!isAudiencesQuintileInFilter(filter) || !isGeoboundaryFilterReadyForAudiencesQuintile(filter)) {
    return { assets, filterApplied: false }
  }

  const params = getParamsFromFilter(filter)
  const filteredAssetsIds = await AudiencesQuintileService.getAssetsToShow(
    meta,
    environment,
    params,
  )

  const filteredAssetsIdsAsMap = new Map(
    filteredAssetsIds.map((id) => [id, true]),
  )
  const filteredAssets = assets.filter((asset) =>
    filteredAssetsIdsAsMap.has(asset.properties.id),
  )

  return { assets: filteredAssets, filterApplied: true }
}
