import { getAppMetadata } from '@/plan/metadata/metadata'
import {
  getTilesetFullNameFor,
  getConnection,
  isAudiencesQuintileEnabled,
} from '@/plan/metadata/metadata.helper'
import { CartoLayer, MAP_TYPES } from '@deck.gl/carto/typed'
import BrowserEnvironmentResolver from '@/helpers/environment.helper'
import { AUDIENCES_QUINTILE_TILESET_LAYER } from '../constant'
import { DataFilterExtension } from '@deck.gl/extensions/typed'
import { getZIndexValueForLayer } from '../layers-z-index.helper'
// import { UpdateParameters } from '@deck.gl/core/typed'

const appMetadata = getAppMetadata()
const browserEnvironmentResolver = BrowserEnvironmentResolver.getInstance()

const tileset = isAudiencesQuintileEnabled(appMetadata)
  ? appMetadata.data_model.audiences_quintile?.tileset
  : 'noTilesetSetForAudiencesQuintile'
if (!tileset) {
  throw new Error('Tileset not found for Audiences Quitile in app metadata')
}

export class AudiencesQuintileLayer extends CartoLayer {
  // shouldUpdateState({ changeFlags }: UpdateParameters<this>): boolean {
  //   console.debug('shouldUpdateState', changeFlags)
  //   if (changeFlags.somethingChanged) {
  //     console.debug('shouldUpdateState  --> YES', changeFlags)
  //   }
  //   return changeFlags.somethingChanged
  // }
  // updateState(params: UpdateParameters<this>) {
  //   console.log('updateState', params)
  //   const { props, changeFlags } = params
  //   if (changeFlags.dataChanged) {
  //     console.log('updateState', changeFlags)
  //   }
  //   console.log('updateState     222222       state:\n', this.state)
  //   super.updateState(params)
  // }
}

AudiencesQuintileLayer.layerName = AUDIENCES_QUINTILE_TILESET_LAYER
AudiencesQuintileLayer.defaultProps = {}

export default AudiencesQuintileLayer

export const propsForAudiencesQuintileLayer = {
  id: AUDIENCES_QUINTILE_TILESET_LAYER,
  layerType: AudiencesQuintileLayer,
  pickable: false,
  data: getTilesetFullNameFor(appMetadata, browserEnvironmentResolver, tileset),
  type: MAP_TYPES.TILESET,
  connection: getConnection(appMetadata, browserEnvironmentResolver),
  minZoom: 3,
  maxZoom: 19,
  tileSize: 512,
  visible: true,
  filled: true,
  stroked: true,
  getPolygonOffset: () =>
    getZIndexValueForLayer(AUDIENCES_QUINTILE_TILESET_LAYER),
  extensions: [new DataFilterExtension({ filterSize: 1 })],
  filterRange: [1, 1],
}
