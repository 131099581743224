import { AudiencesQuintile, Environment, Metadata } from '@workspaces/types'
import { AudiencesQuintileBrowserService } from '@workspaces/services'

export default {
  async getAudiencesQuintileOptions(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    params: AudiencesQuintile.Params,
  ): Promise<string[]> {
    const data: AudiencesQuintile.Option[] =
      await AudiencesQuintileBrowserService.getAudiencesQuintileOptions(
        meta,
        environment,
        params,
      )

    const dataFormatted = data.map((item) => item.name)

    return dataFormatted
  },
  async getZipCodesByQuintile(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    params: AudiencesQuintile.RunFilterParams,
  ): Promise<Map<number, number>> {
    const data: AudiencesQuintile.ZipCodesByQuintile[] =
      await AudiencesQuintileBrowserService.getZipCodesByQuintile(
        meta,
        environment,
        params,
      )

    const dataFormatted = new Map<number, number>()
    data.forEach((item) => {
      dataFormatted.set(item.zipCode, item.quintile)
    })
    return dataFormatted
  },
}
