import {
  Asset,
  AudiencesQuintile,
  Environment,
  Metadata,
} from '@workspaces/types'
import { AudiencesQuintileService } from '@workspaces/services'

export default {
  async getAssetsToShow(
    meta: Metadata.AppMetadata,
    environment: Environment.EnvironmentResolver,
    params: AudiencesQuintile.RunFilterParams,
  ): Promise<string[]> {
    const data: Asset.AssetId[] =
      await AudiencesQuintileService.getAssetsToShow(meta, environment, params)

    const dataFormatted = data.map((item) => item.asset_id)

    return dataFormatted
  },
}
