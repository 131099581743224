import { AudienceFeatureVersion } from './audience.type'
import { ProximityFeatureVersion } from './proximity.type'
import { InsightTransformation } from './insight.type'
import { MediaTypeFeatureVersion } from './media.type'
import { FilePOIColumns } from './export.type'
import { Auth, Carto, Package, Poi } from '.'
import { Basemap, BasemapOption } from './basemap.type'

type Nullable<T> = T | null

export interface Language {
  id: string
  name: string
  locale?: string
}

export interface Languages {
  enable: boolean
  options: Language[]
}

export interface LocalStorageKeys {
  lang: string
  auth: string
  firebase_token: string
}

export interface Units {
  distance: string
  currency: string
  currency_symbol: string
}

export interface FeatureAudit {
  enable: boolean
}

export interface FeatureDashboard {
  show_others_groups_tab: boolean
  show_public_plans_by_country_and_user: boolean
}

export interface FeatureShare {
  enable: ConfigByEnvironment<boolean>
  retrieve_in_blocks_of: number
  expiracyOffset: number
  password: boolean
  package?: {
    showPrice: boolean
  }
}

export interface FeatureNewPlan {
  enable: boolean
}

export interface FeatureSavePlan {
  enable_save_as: boolean
  enable: boolean
}

export interface MappingField {
  model: 'assets'
  modelField: string
  exportField: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  formatFunction?: Function
  avoidApplyingNumberFormat?: boolean
  avoidInSharedPlan?: boolean
}

export interface FeatureExport {
  enable: boolean
  include_total_assets_column: boolean
  add_tab_xlsx_pois: boolean
  fields_mapping: MappingField[]
  columnsPOIs: string[]
  columnsAllPOIs: string[]
  columnsCustomPOIs: string[]
  fileColumnTitles: FilePOIColumns[]
}
export interface FeatureInsightsTabs {
  impressions: boolean
  demographics: boolean
  top_indexing: boolean
  table_view: boolean
}

export type DemographicsDataFormatterFunction = (value: number) => number
export interface DemographicsDataFormatter {
  index: DemographicsDataFormatterFunction
  panel: DemographicsDataFormatterFunction
  market: DemographicsDataFormatterFunction
}

export interface DemographicsInsightsFeatures {
  simpliest_chart: 'pie' | 'histogram'
  template?: InsightTransformation[]
  pre_filter_audiences_classes: boolean
  sort_by_values: boolean
  identify_gender_charts: string[]
  format: DemographicsDataFormatter
}

export interface TableViewInsightsFeatures {
  name: boolean
  market: boolean
  panel: boolean
  index: boolean
}

export interface FeatureInsightsDetail {
  id_field: string
  second_id_field: Nullable<string>
  show_image: boolean
  show_advanced_address: boolean
  show_media_type: boolean
  show_audience: boolean
}

export interface FeatureInsights {
  enable: boolean
  detail: FeatureInsightsDetail
  tabs: FeatureInsightsTabs
  features: {
    demographics: DemographicsInsightsFeatures
    table_view: TableViewInsightsFeatures
  }
}

export interface FeatureManagePoisTypes {
  coords: boolean
  geocoding: boolean
}

export interface CustomPoisExpiration {
  enable: boolean
  days: number
}

export interface FeatureManagePois {
  enable: boolean
  types: FeatureManagePoisTypes
  default_type: Poi.CustomPOIsImportMode
  distinguish_between_user_and_admin: boolean
  max_custom_pois_per_file: number
  max_custom_pois_geocoding: number
  max_custom_pois_per_file_admin_user: number
  max_custom_pois_geocoding_admin_user: number
  xlsx_template_geocode: CustomPOIsXlsxTemplate
  xlsx_template_coords: CustomPOIsXlsxTemplate
  xlsx_header_download: string[]
  custom_pois_expiration: CustomPoisExpiration
}

export interface FeaturePopup {
  title_i18n: string
  title_id_field: string
  subtitle_id_field: string
  show_picture: boolean
  show_address: boolean
  show_streetview: boolean
  show_coordinates: boolean
  show_facing_direction: boolean
  show_angle_to_map_north: boolean
  show_panel_name: boolean
  show_type: boolean
  show_impressions: boolean
  show_audiences: boolean
  show_exclude: boolean
  show_details: boolean
  shared_plan_show_details: boolean
  show_isoline: boolean
  show_description?: boolean
}

export interface FeatureHelp {
  enable: boolean
  url: string
}

export interface MultiTabsFilters {
  enable: boolean
  max_tabs: number
}

export interface ViewState {
  longitude: number
  latitude: number
  zoom: number
  bearing: number
  pitch: number
}

export interface Legend {
  colors: number[]
  enable_product_selection: boolean
  default_product_selection: string
  exclude_assets: boolean
  facing_direction: boolean
  angle_to_map_north: boolean
  show_geoboundaries_layer: boolean
  show_pois_layer: boolean
  show_product_selection: boolean
  title: string
  show_buffers_visibility_control: boolean
  default_buffers_visibility: boolean
  show_assets_ids_layer: boolean
}

export interface Map {
  layers_precedence: string[]
  asset_details_over_map: boolean
  fixed_cluster_size: number
  zoom_hide_cluster: number
  view_state: ViewState
  max_bbox: number[]
  legend: Legend
  highlightSelectedMarker: boolean
  screenshot_btn: boolean
}

export interface Proximity {
  max_zip_codes_per_file: number
  multicolor: boolean
  ramp_color: string[]
  others_color: string
  default_color: string
}

export interface ConfigByEnvironment<T> {
  dev: T
  staging: T
  prod: T
  local?: T
}

export type EnableByEnvironment = ConfigByEnvironment<boolean>

export interface DataByEnvironment {
  dev: string
  staging: string
  prod: string
}

export interface Widgets {
  show_button_tooltip_as_copies: boolean
}

export interface FeatureAssets {
  exclude: boolean
  skip_popup_and_go_to_sidebar?: boolean
  show_sidebar_when_double_click_on_list?: boolean
}

export interface CustomGeoboundaries {
  enable: boolean
  upload_file: boolean
  upload_file_max_size_mb: number
  save_group_from_plan: boolean
}

export interface DashboardColumns {
  locale_keys: string[]
  model_accessors: string[]
}

export interface FeaturesPlan {
  dashboard_columns: DashboardColumns
  enable_comments: boolean
  save_brand: boolean
  save_client: boolean
}

export interface FeaturesPackages {
  enable: boolean
  availability_range: {
    value: number
    text: string
    color: string
    description: string
  }[]
  states_style: {
    [key in Package.State]: {
      background: string
      foreground: string
    }
  }
  dashboard_columns: DashboardColumns
  expiration: {
    booking_confirmation: number
    cancellation_time_limit: number
  }
}

export interface FeatureComments {
  enable: boolean
  attachments: {
    enable: boolean
    max_size_mb: number
  }
}

export interface AudiencesQuintile {
  enabled: boolean
}

export interface CustomPOIsXlsxTemplate {
  template: string
  mandatory_fields_in_template: string[]
  mandatory_fields_with_data: string[]
  at_least_one_field_with_data_within?: string[]
  optional_fields?: string[]
}
export interface CustomPOIsFeatures {
  xlsx_template_geocode: CustomPOIsXlsxTemplate
  xlsx_template_coords: CustomPOIsXlsxTemplate
}

export interface Features {
  permission_resolver: Auth.PermissionResolver
  comments: FeatureComments
  audiences_quintile: AudiencesQuintile
  packages: FeaturesPackages
  plan: FeaturesPlan
  assets: FeatureAssets
  audit: FeatureAudit
  dashboard: FeatureDashboard
  share: FeatureShare
  new_plan: FeatureNewPlan
  save_plan: FeatureSavePlan
  export: FeatureExport
  insights: FeatureInsights
  manage_pois: FeatureManagePois
  popup: FeaturePopup
  popup_minimal: FeaturePopup
  popup_share: FeaturePopup
  help: FeatureHelp
  multiTabsFilters: MultiTabsFilters
  map: Map
  proximity: Proximity
  widgets: Widgets
  custom_geoboundaries: CustomGeoboundaries
  custom_pois?: CustomPOIsFeatures
}

export interface PlanFiltersWidgetCountries {
  enable: boolean
  default_selection_iso3: string[]
}

export interface PlanFiltersWidgetAssets {
  enable: boolean
  enable_only_admin: boolean
  xlsx_column_name: string
  example_file: string
  upload_by_file: boolean
  upload_by_paste: boolean
  default_option: 'file' | 'paste'
  upload_inventory_permit_excluded: boolean
}

export interface PlanFiltersWidgetGeoBoundariesCustomDrawing {
  enable: boolean
  polygon: boolean
  circle: boolean
  route: boolean
}

export interface PlanFiltersWidgetGeoBoundariesSamples {
  name: string
  link: string
}

export interface PlanFiltersWidgetGeoBoundaries {
  enable: boolean
  permit_exclude: boolean
  custom_drawing: PlanFiltersWidgetGeoBoundariesCustomDrawing
  sample_files: PlanFiltersWidgetGeoBoundariesSamples[]
}

export interface PlanFiltersWidgetAudiencesByIndex {
  name: string
  enable: boolean
  max_value: number
  default_min_value: number
  default_max_value: number
}

export interface PlanFiltersWidgetAudiencesByPercentage {
  name: string
  enable: boolean
  selected_min_value: number
  selected_max_value: number
}

export enum DELIVERY_HUMAN_REPRESENTATION {
  Month,
  Week,
}
export interface PlanFiltersWidgetAudiences {
  version: AudienceFeatureVersion
  enable: EnableByEnvironment
  show_delivery_selector: EnableByEnvironment
  delivery_human_representation: DELIVERY_HUMAN_REPRESENTATION
  max_AND: number
  max_OR: number
  by_index: PlanFiltersWidgetAudiencesByIndex
  by_percentage: PlanFiltersWidgetAudiencesByPercentage
}

export interface PlanFiltersWidgetMediaTypeTypes {
  l1panelclass: string
  l2product: string
  l3subproduct?: string
  l4?: string
  l5?: string
  l6?: string
  l7?: string
  l8?: string
  l9?: string
}

export interface PlanFiltersWidgetMediaType {
  enable: boolean
  version: MediaTypeFeatureVersion
  filter_digital: boolean
  types: PlanFiltersWidgetMediaTypeTypes
}

export interface PlanFiltersWidgetListAssets {
  enable: boolean
  fields: string[]
  sortBy: string
  add_copy_btn: boolean
  switch_to_list_view: boolean
}

export interface PlanFiltersWidgetOptimizeDistributionMaxNumberAssets {
  enable: boolean
  value: number
}

export interface PlanFiltersWidgetOptimizeDistributionMinDistanceBetweenAssets {
  enable: boolean
  units: string
  max_distance: number
  selected_distance: number
  num_elements_direct_query: number
}

export enum TYPES_OPTIMIZE_DISTRIBUTION {
  Impressions = 'impressions',
  Audience = 'audience',
}

export interface PlanFiltersWidgetOptimizeDistribution {
  enable: boolean
  disable_production: boolean
  prioritary_regions: number[]
  excluded_regions: number[]
  types: string[]
  max_number_assets: PlanFiltersWidgetOptimizeDistributionMaxNumberAssets
  min_distance_between_assets: PlanFiltersWidgetOptimizeDistributionMinDistanceBetweenAssets
}

export interface PlanFiltersWidgetProximityTypesRadiusMaxAssetsPerPoi {
  enable: boolean
  options: number[]
}

export interface PlanFiltersWidgetProximityTypesRadius {
  id: string
  name: string
  enable: boolean
  units: string
  min_distance: number
  max_distance: number
  double_buffer: boolean
  selected_min_distance: number
  selected_max_distance: number
  max_assets_per_poi: PlanFiltersWidgetProximityTypesRadiusMaxAssetsPerPoi
}

export interface PlanFiltersWidgetProximityTypesDriving {
  id: string
  name: string
  enable: boolean
  options: number[]
  selected: number
}

export interface PlanFiltersWidgetProximityTypesWalking {
  id: string
  name: string
  enable: boolean
  options: number[]
  selected: number
}

export interface PlanFiltersWidgetProximityTypes {
  radius: PlanFiltersWidgetProximityTypesRadius
  driving: PlanFiltersWidgetProximityTypesDriving
  walking: PlanFiltersWidgetProximityTypesWalking
}

export interface PlanFiltersWidgetProximityModal {
  show_brands: boolean
  show_custom_pois: boolean
  show_tags: boolean
  show_locations: boolean
  search_by: string[]
}

export interface PlanFiltersWidgetProximity {
  enable: boolean
  version: ProximityFeatureVersion
  modal: PlanFiltersWidgetProximityModal
  types: PlanFiltersWidgetProximityTypes
  max_isochrones: number
  pois_isochrones_fields: string[]
}

export interface PlanFiltersWidgetPackages {
  enable: boolean
  price_range: [number, number]
}

export interface PlanFilters {
  widget_countries: PlanFiltersWidgetCountries
  widget_assets: PlanFiltersWidgetAssets
  widget_geoboundaries: PlanFiltersWidgetGeoBoundaries
  widget_audiences: PlanFiltersWidgetAudiences
  widget_media_type: PlanFiltersWidgetMediaType
  widget_list_assets: PlanFiltersWidgetListAssets
  widget_optimize_distribution: PlanFiltersWidgetOptimizeDistribution
  widget_proximity: PlanFiltersWidgetProximity
  widget_packages: PlanFiltersWidgetPackages
}

export enum DataModelFieldTypes {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  Array = 'array',
  Geography = 'geography',
  ArrayNumbers = 'array_numbers',
  ArrayStrings = 'array_strings',
}

export interface DataModelField {
  id: string
  type: DataModelFieldTypes
  visible: boolean
  filterable: boolean
  display: boolean
  startup?: boolean
  background?: boolean
}

export interface DataModelFieldAutocomplete extends DataModelField {
  autocomplete: boolean
}

export interface CacheModel {
  dbName: string
  store: string
  key: string
  key_last_update: string
  version: number
}
export interface DataModelBase {
  table_name: string
  cache?: CacheModel
  columns: DataModelField[] | DataModelFieldAutocomplete[]
  chunk_size?: number
}

export interface AudiencesQuintileDataModel
  extends DataModelBase,
    DataModelWithTileSet {}

export interface AssetsDataModel extends DataModelBase {
  cluster_icon: string
}

export interface DataModelWithTileSet {
  tileset: string
}

export interface Autocomplete {
  autocomplete_locations: string
  autocomplete: string
}

export interface POIsDataModel
  extends DataModelBase,
    DataModelWithTileSet,
    Autocomplete {}

export interface Region {
  id: number
  name: string
  order: number
  countries: number[]
  geom: boolean
}
export interface RegionsHierarchy {
  region_1: Region
  region_2: Region
  region_3: Region
  region_4: Region
  region_5: Region
  region_6: Region
  region_7: Region
  region_8?: Region
  region_9?: Region
  region_10?: Region
  region_11?: Region
  region_12?: Region
  region_13?: Region
  region_14?: Region
  region_15?: Region
  region_16?: Region
  region_17?: Region
  region_18?: Region
}
export interface DataModelRegions extends DataModelBase {
  partitioned_column: string
  regions_hierarchy: RegionsHierarchy
}

export interface DataModel {
  regions: DataModelRegions
  assets: AssetsDataModel
  custom_pois: DataModelBase
  custom_pois_address_tmp: DataModelBase
  audiences_quintile?: AudiencesQuintileDataModel
  pois: POIsDataModel
  audiences: DataModelBase
  audiences_classes: DataModelBase
  panelcounts: DataModelBase
  pois_isochrones: DataModelBase
  shared_plans_assets: DataModelBase
  custom_geoboundaries: DataModelBase
  packages: DataModelBase
  packages_availability: DataModelBase
  assets_optimize_distribution: DataModelBase
}

export interface MetaRegion {
  type: string
  name: string
}

export interface MetaAssets {
  type: string
  name: string
  hide?: boolean
}

export interface Country {
  iso3: string
  iso2: string
  name: string
  id: number
  id_legacy?: number
  meta_region?: MetaRegion[]
  meta_assets?: MetaAssets[]
  carto_me_value?: string
  geocoder?: string
}

export enum RemoteFunction {
  FirebaseToken = 'firebase_token',
  Audit = 'audit',
  BigQueryCustomPOIS = 'big_query_custom_POIS',
  Aida = 'aida',
  SharedPlan = 'share_plan',
  Isochrones = 'isochrones',
  Auth0 = 'auth0',
  CustomGeoboundaries = 'custom_geoboundaries',
  SignedUrls = 'signed_urls',
  OptimizeDistribution = 'optimize_distribution',
  Geocoding = 'geocoding',
}

export interface RemoteFunctions {
  firebase_token: ConfigByEnvironment<string>
  audit: ConfigByEnvironment<string>
  big_query_custom_POIS: ConfigByEnvironment<string>
  aida: ConfigByEnvironment<string>
  share_plan: ConfigByEnvironment<string>
  isochrones: ConfigByEnvironment<string>
  auth0?: ConfigByEnvironment<string>
  custom_geoboundaries: ConfigByEnvironment<string>
  signed_urls?: ConfigByEnvironment<string>
  optimize_distribution?: ConfigByEnvironment<string>
  geocoding?: ConfigByEnvironment<string>
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
}

export interface BasemapsOptions {
  enable: boolean
  default: Basemap
  options: BasemapOption[]
}

export interface AppConfig {
  carto_client_id: ConfigByEnvironment<string>
  front_url: string[]
  carto_organization?: string
  firebase: ConfigByEnvironment<FirebaseConfig>
  remote_functions: RemoteFunctions
  buckets?: {
    attachments?: ConfigByEnvironment<string>
  }
  basemaps: BasemapsOptions
}

export interface AppRole {
  token_property: keyof Carto.CartoMe
  value: string
  role: Auth.Role
}

export interface LoginPageSetup {
  welcome_message?: string
  contact_message?: string
  contact_email?: string
  show_terms_of_use: boolean
}

export interface AppMetadata {
  login_page_setup?: LoginPageSetup
  app_title: string
  logo: string
  terms_of_use_url?: string
  app_config: AppConfig
  flavour: string
  flavour_testing: string
  roles: AppRole[]
  auth0_user_key: string
  auth0_account_id_key: string
  base_url: string
  bq_project: ConfigByEnvironment<string>
  carto_events_url: string
  connection: string
  analytics_toolbox_base_url: string
  languages: Languages
  local_storage_keys: LocalStorageKeys
  units: Units
  data_model: DataModel
  features: Features
  plan_filters: PlanFilters
  countries: Country[]
}
