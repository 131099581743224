import { getAppMetadata } from './metadata'
import {
  Metadata,
  Insight,
  Audience,
  Media,
  Proximity,
  Export,
  Environment,
  Package,
  Auth,
  Poi,
} from '@workspaces/types'

export const getGenericRegionFromRegion = (regionId: number): string => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  for (genericRegionsKey in genericRegions) {
    if (genericRegions[genericRegionsKey]?.id === regionId) {
      return genericRegionsKey
    }
  }

  throw Error(
    `Trying to get generic region for value ${regionId}. Check metadata file to verify the value is correct`,
  )
}

export const getRegionFromGenericRegion = (region: string): Metadata.Region => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  for (genericRegionsKey in genericRegions) {
    if (genericRegionsKey.toLocaleLowerCase() === region.toLocaleLowerCase()) {
      const regionValue = genericRegions[genericRegionsKey]
      if (regionValue === undefined) {
        throw Error(
          `Trying to get region value for generic region ${region}. Check metadata file to verify the value is correct`,
        )
      }
      return regionValue
    }
  }

  throw Error(
    `Trying to get region value for generic region ${region}. Check metadata file to verify the value is correct`,
  )
}

export const getRegionsTypeId = (): string[] => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  const regions: string[] = []
  for (genericRegionsKey in genericRegions) {
    const regionValue = genericRegions[genericRegionsKey]
    if (regionValue === undefined) {
      throw Error(
        `Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`,
      )
    }
    regions.push(String(regionValue.id))
  }

  return regions
}

export const getRegionsGenericNameFromTypeId = (typeId: number): string => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  for (genericRegionsKey in genericRegions) {
    const regionValue = genericRegions[genericRegionsKey]
    if (regionValue === undefined) {
      throw Error(
        `Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`,
      )
    }
    if (regionValue.id === typeId) {
      return genericRegionsKey
    }
  }

  throw Error(
    `Not found region name formatted for type_id value ${typeId}. Check metadata file to verify the value is correct`,
  )
}

export const getRegionsTypeIdOrdered = (): number[] => {
  const genericRegions = getAppMetadata().data_model.regions.regions_hierarchy

  let genericRegionsKey: keyof Metadata.RegionsHierarchy
  const regions: Metadata.Region[] = []
  for (genericRegionsKey in genericRegions) {
    const regionValue = genericRegions[genericRegionsKey]
    if (regionValue === undefined) {
      throw Error(
        `Trying to get all regions names formatted, but key ${genericRegionsKey} not found. Check metadata file to verify the value is correct`,
      )
    }
    regions.push(regionValue)
  }

  const regionsOrdered = regions.sort((a, b) => a.order - b.order)
  const regionsOrderedTypeId = regionsOrdered.map((region) => region.id)

  return regionsOrderedTypeId
}

export function showExcludedRangeBufferForPOIs(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_proximity.types.radius.double_buffer
}

export function getBqProject(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string {
  const env = environment.getEnvironment()
  const bqProject = meta.bq_project
  switch (env) {
    case Environment.Environment.Development:
      return bqProject.dev
    case Environment.Environment.Staging:
      return bqProject.staging
    case Environment.Environment.Production:
      return bqProject.prod
  }
  throw new Error(
    `Could not find BQ project for environment ${env}. Check metadata file to verify values are correctly set under bq_project parameter`,
  )
}

export function getTilesetFullNameFor(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  tileset: string,
): string {
  const env = environment.getEnvironment()
  const isTesting = environment.isTesting()
  const project = getBqProject(meta, environment)
  const dataset = isTesting ? `${env}_testing` : env
  const tilesetFullName = `${project}.${dataset}.${tileset}`
  return tilesetFullName
}

export function getConnection(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string {
  const env = environment.getEnvironment()
  return `${meta.connection}_${env}`
}

export function isDemographicsTabEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.insights.enable && meta.features.insights.tabs.demographics
  )
}

export function isImpressionsTabEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.insights.enable && meta.features.insights.tabs.impressions
  )
}

export function isOverviewTabEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.insights.enable && meta.features.insights.tabs.table_view
}

export function isTopIndexTabEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.insights.enable && meta.features.insights.tabs.top_indexing
  )
}

export function getMaxCustomPOIsPerFile(
  meta: Metadata.AppMetadata,
  isAdmin: boolean,
): number {
  if (meta.features.manage_pois.distinguish_between_user_and_admin) {
    return isAdmin
      ? meta.features.manage_pois.max_custom_pois_per_file_admin_user
      : meta.features.manage_pois.max_custom_pois_per_file
  }
  return meta.features.manage_pois.max_custom_pois_per_file
}

export function getMaxCustomPOIsByGeocoding(
  meta: Metadata.AppMetadata,
  isAdmin: boolean,
): number {
  if (meta.features.manage_pois.distinguish_between_user_and_admin) {
    return isAdmin
      ? meta.features.manage_pois.max_custom_pois_geocoding_admin_user
      : meta.features.manage_pois.max_custom_pois_geocoding
  }
  return meta.features.manage_pois.max_custom_pois_geocoding
}

export function getMapClusterFixedSize(meta: Metadata.AppMetadata): number {
  return meta.features.map.fixed_cluster_size
}

export function getMaxZipCodesperFile(meta: Metadata.AppMetadata): number {
  return meta.features.manage_pois.max_custom_pois_per_file
}

export function getSriteIndexForLegendIcon(index: number): number {
  const meta = getAppMetadata()
  let secureIndex = index
  if (index >= meta.features.map.legend.colors.length) {
    secureIndex = index % meta.features.map.legend.colors.length
  }

  return meta.features.map.legend.colors[secureIndex]
}

export function enableSelectingByProductInLegend(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.enable_product_selection
}

export function defaultProductSelectionInLegend(
  meta: Metadata.AppMetadata,
): string {
  return meta.features.map.legend.default_product_selection
}

export function getIdentifiersForGenderCharts(): string[] {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.identify_gender_charts
}

export function needToPrefilterDemographicsData(): boolean {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics
    .pre_filter_audiences_classes
}

export function needToSortDataByValuesInDemographicsData(): boolean {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.sort_by_values
}

export function getSimpliestChartForDemographicsInsigths(): string {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.simpliest_chart
}

export function getTemplatetForDemographicsInsigths():
  | Insight.InsightTransformation[]
  | undefined {
  const meta = getAppMetadata()
  return meta.features.insights.features.demographics.template
}

export function isAuditEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.audit.enable
}

export function getCartoAppConfiguration(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): {
  clientId: string
  organization?: string
} {
  const env = environment.getEnvironment()
  const cartoOrganization = meta.app_config.carto_organization

  switch (env) {
    case Environment.Environment.Development:
      return {
        clientId: meta.app_config.carto_client_id.dev,
        organization: cartoOrganization,
      }
    case Environment.Environment.Staging:
      return {
        clientId: meta.app_config.carto_client_id.staging,
        organization: cartoOrganization,
      }

    case Environment.Environment.Production:
      return {
        clientId: meta.app_config.carto_client_id.prod,
        organization: cartoOrganization,
      }

    default:
      throw Error(
        `Environment ${env} not found. Check .env file to verify VUE_APP_ENV is set.`,
      )
  }
}

export function isMultiCountry(meta: Metadata.AppMetadata): boolean {
  return meta.countries.length > 1
}

export function getCountryISO2ForNotMultiCountry(
  meta: Metadata.AppMetadata,
): string {
  return meta.countries[0].iso2
}

export function getCountriesIdsFromMetadata(
  meta: Metadata.AppMetadata,
): number[] {
  return meta.countries.map((country) => country.id)
}

export function isEnableForCurrentEnvironment(
  enableByEnvironment: Metadata.EnableByEnvironment,
  environment: Environment.EnvironmentResolver,
): boolean {
  const env = environment.getEnvironment()
  switch (env) {
    case Environment.Environment.Development:
      return enableByEnvironment.dev
    case Environment.Environment.Staging:
      return enableByEnvironment.staging
    case Environment.Environment.Production:
      return enableByEnvironment.prod
    default:
      console.error(
        `🛑 Unrecognized environment value: ${env} . Check .env file to verify VUE_APP_ENV is set.`,
      )
      return false
  }
}

export function getAudienceFeatureVersion(): Audience.AudienceFeatureVersion {
  return getAppMetadata().plan_filters.widget_audiences.version
}

export function getProximityFeatureVersion(): Proximity.ProximityFeatureVersion {
  return getAppMetadata().plan_filters.widget_proximity.version
}

export function getMediaTypeFeatureVersion(): Media.MediaTypeFeatureVersion {
  const metaData = getAppMetadata()
  const version = metaData.plan_filters.widget_media_type.version
  return version
}

export function isTotalAssetsColumnIncludedInExport(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.export.include_total_assets_column
}

export function getDefaultMethodForUploadingAssets(
  meta: Metadata.AppMetadata,
): string {
  return meta.plan_filters.widget_assets.default_option
}

export function showMethodFileForUploadingAssets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.upload_by_file
}

export function showMethodPasteForUploadingAssets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.upload_by_paste
}

export function getExportFileColumns(
  meta: Metadata.AppMetadata,
): Export.FilePOIColumns[] {
  return meta.features.export.fileColumnTitles
}

export function isExcludedFeatureForGeoboundary(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_geoboundaries.permit_exclude
}

export function getMediaTypeNameForCountry(
  meta: Metadata.AppMetadata,
  key: string,
  countryId: number,
): string | null {
  const countryInfo = meta.countries.find((country) => country.id === countryId)
  if (countryInfo && countryInfo.meta_assets) {
    return (
      countryInfo.meta_assets.find(
        (t) => t.type === key || t.type.startsWith(key),
      )?.name || null
    )
  }
  return null
}

export function getMediaTypeVisibilityForCountry(
  meta: Metadata.AppMetadata,
  key: string,
  countryId: number,
): boolean {
  const countryInfo = meta.countries.find((country) => country.id === countryId)
  if (countryInfo && countryInfo.meta_assets) {
    const hide =
      countryInfo.meta_assets.find(
        (t) => t.type === key || t.type.startsWith(key),
      )?.hide || false
    return !hide
  }
  return true
}

export function isExcludedAssetsLegenedEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.exclude_assets
}

export function uploadInventoryPermitExcludedAssets(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_assets.upload_inventory_permit_excluded
}

export function isSaveAsEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.save_plan.enable && meta.features.save_plan.enable_save_as
  )
}

export function isSharePlanEnabled(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): boolean {
  const setupByEnvironment = meta.features.share.enable
  const isEnabled = isEnableForCurrentEnvironment(
    setupByEnvironment,
    environment,
  )
  return isEnabled
}

export function showAssetDetailOverMap(meta: Metadata.AppMetadata): boolean {
  return meta.features.map.asset_details_over_map
}

export function getZoomLevelForDeclustering(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.map.zoom_hide_cluster
}

export function getFormatFunctionForDemographicsInsights(
  meta: Metadata.AppMetadata,
  property: Insight.InsightProperties,
): Metadata.DemographicsDataFormatterFunction {
  const formatFunction = meta.features.insights.features.demographics.format
  switch (property) {
    case Insight.InsightProperties.Index:
      return formatFunction.index
    case Insight.InsightProperties.Market:
      return formatFunction.market
    case Insight.InsightProperties.Panel:
      return formatFunction.panel
    default:
      throw Error(
        `Property ${property} not found for selecting function formatter for demographics insights. Check metadata file under property:features.insights.features.demographics.format, to verify the value is correct`,
      )
  }
}

export function getAssetsBlockSize(meta: Metadata.AppMetadata): number {
  return meta.features.share.retrieve_in_blocks_of
}

export function showButtonsTooltipAsCopies(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.widgets.show_button_tooltip_as_copies
}

export function getCacheInforForModel(
  model: Metadata.DataModelBase,
): Metadata.CacheModel {
  const cache = model.cache
  if (!cache) {
    throw Error(
      `Cache information not found for model ${model.table_name}. Check metadata file to verify the value is correct`,
    )
  }
  return cache
}

export function showFacingDirectionInLegend(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.facing_direction
}

export function showAngleMapNorthInLegend(meta: Metadata.AppMetadata): boolean {
  return meta.features.map.legend.angle_to_map_north
}

export function showBufferVisibilityControl(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.map.legend.show_buffers_visibility_control
}

export function getChunkSizeForDownloadingAssets(
  meta: Metadata.AppMetadata,
): number {
  return meta.data_model.assets.chunk_size ?? 50000
}

export function isExcludeAssetsFeatureEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.assets.exclude
}

export function switchToListViewFeatureAvailable(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.plan_filters.widget_list_assets.switch_to_list_view
}

export function getCurrency(meta: Metadata.AppMetadata): string {
  return meta.units.currency
}

export function getCurrencySymbol(meta: Metadata.AppMetadata): string {
  return meta.units.currency_symbol
}

export function isPackagagesFeatureEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.packages.enable
}

export function skipPopupAndGoToSidebar(meta: Metadata.AppMetadata): boolean {
  return meta.features.assets.skip_popup_and_go_to_sidebar ?? false
}

export function showAssetsSidebarWhenDoubleClickOnAssetList(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.assets.show_sidebar_when_double_click_on_list ?? false
}

export function getColorForAvailability(
  meta: Metadata.AppMetadata,
  value: number,
): string {
  const availabilityRange = meta.features.packages.availability_range
  for (const elementInRange of availabilityRange) {
    if (elementInRange.value <= value) {
      return elementInRange.color
    }
  }
  return availabilityRange[availabilityRange.length - 1].color
}

export function getDescriptionForAvailability(
  meta: Metadata.AppMetadata,
  value: number,
): string {
  const availabilityRange = meta.features.packages.availability_range
  for (const elementInRange of availabilityRange) {
    if (elementInRange.value <= value) {
      return elementInRange.description
    }
  }
  return availabilityRange[availabilityRange.length - 1].color
}

export function getTextForAvailability(
  meta: Metadata.AppMetadata,
  value: number,
): string {
  const availabilityRange = meta.features.packages.availability_range
  for (const elementInRange of availabilityRange) {
    if (elementInRange.value <= value) {
      return elementInRange.text
    }
  }
  return availabilityRange[availabilityRange.length - 1].text
}

export function getColorForState(
  meta: Metadata.AppMetadata,
  value: Package.State,
): { background: string; foreground: string } {
  const color = meta.features.packages.states_style[value]
  if (!color) {
    throw Error(
      `Color not found for state ${value}. Check metadata file under key: states_style to verify the value is correct`,
    )
  }
  return color
}

export function getDashboardColumnsFor(
  dashboardColumns: Metadata.DashboardColumns,
  modelAccseors: boolean,
): string[] {
  if (modelAccseors) {
    return dashboardColumns.model_accessors
  }
  return dashboardColumns.locale_keys
}

export function getExportColumnsIndexToAvoidApplyingNumberFormat(
  meta: Metadata.AppMetadata,
): number[] {
  const exportColumns = meta.features.export.fields_mapping
  // get the index of the columns that has property avoidApplyingNumberFormat set to true
  const columnsToDoNotApplyNumberFormat = exportColumns
    .map((column, index) => {
      if (column.avoidApplyingNumberFormat) {
        return index
      }
      return -1
    })
    .filter((index) => index !== -1)
  return columnsToDoNotApplyNumberFormat
}

export function isPlanCommentsEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.plan.enable_comments
}

export function isManagePOIsEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.manage_pois.enable
}

export function isPOIsEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.plan_filters.widget_proximity.enable
}

export function getPermissionResolverVersion(
  meta: Metadata.AppMetadata,
): Auth.PermissionResolver {
  return meta.features.permission_resolver
}

export function isCustomGeoboundaryDatasetEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.custom_geoboundaries.enable
}

export function isPOIsMulticolorEnabled(meta: Metadata.AppMetadata): boolean {
  return meta.features.proximity.multicolor
}

export function getPOIsDefaultColor(meta: Metadata.AppMetadata): string {
  return meta.features.proximity.default_color
}

export function getPOIsColors(meta: Metadata.AppMetadata): {
  ramp: string[]
  others: string
} {
  return {
    ramp: meta.features.proximity.ramp_color,
    others: meta.features.proximity.others_color,
  }
}

export function getPackageExpireConfirmationTime(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.packages.expiration.booking_confirmation
}

export function getPackageExpireCancelTime(meta: Metadata.AppMetadata): number {
  return meta.features.packages.expiration.cancellation_time_limit
}

export function getISO2Flavour(meta: Metadata.AppMetadata): string {
  return meta.flavour
}

export function getBucketForAttachments(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
): string {
  const env = environment.getEnvironment()
  if (!meta.app_config.buckets) {
    throw new Error(
      'Buckets setup not found in metadata file. Check app_config in metadata file',
    )
  }
  if (!meta.app_config.buckets.attachments) {
    throw new Error(
      'Attachments bucket setup not found in metadata file. Check app_config in metadata file',
    )
  }

  const bucketsByEnvironment = meta.app_config.buckets.attachments
  switch (env) {
    case 'dev':
      return bucketsByEnvironment.dev
    case 'stg':
      return bucketsByEnvironment.staging
    case 'pro':
      return bucketsByEnvironment.prod
    default:
      throw Error(
        `🛑 Getting remote function. Unrecognized environment value: ${env} . Check .env file to verify VUE_APP_ENV is set.`,
      )
  }
}

export function getCommentAttachmentsMaxSizeInMB(
  meta: Metadata.AppMetadata,
): number {
  return meta.features.comments.attachments.max_size_mb
}

export function getAreAtttachmentsEnabled(meta: Metadata.AppMetadata): boolean {
  return (
    meta.features.comments.enable && meta.features.comments.attachments.enable
  )
}

export function getTermsOfUseLink(
  meta: Metadata.AppMetadata,
): string | undefined {
  return meta.terms_of_use_url
}

export function getWelcomeMessage(
  meta: Metadata.AppMetadata,
): string | undefined {
  return meta.login_page_setup?.welcome_message
}

export function getContactMessage(
  meta: Metadata.AppMetadata,
): { message: string; email: string } | undefined {
  if (!meta.login_page_setup?.contact_message) {
    return undefined
  }
  if (
    meta.login_page_setup?.contact_message &&
    meta.login_page_setup?.contact_email
  ) {
    return {
      message: meta.login_page_setup.contact_message,
      email: meta.login_page_setup.contact_email,
    }
  }
  throw new Error('Contact message is enabled but email is not set')
}

export function showTermsOfUseAtLogin(meta: Metadata.AppMetadata): boolean {
  return meta.login_page_setup?.show_terms_of_use ?? false
}

export function getSupportedCountries(meta: Metadata.AppMetadata): string[] {
  return meta.countries.map((country) => country.iso2)
}

export function getMandatoryHeaderForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return meta.features.manage_pois.xlsx_template_coords
        .mandatory_fields_in_template
    case Poi.CustomPOIsImportMode.Geocode:
      return meta.features.manage_pois.xlsx_template_geocode
        .mandatory_fields_in_template
  }
  throw new Error(
    `Mandatory fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getMandatoryFiledsWithDataForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return meta.features.manage_pois.xlsx_template_coords
        .mandatory_fields_with_data
    case Poi.CustomPOIsImportMode.Geocode:
      return meta.features.manage_pois.xlsx_template_geocode
        .mandatory_fields_with_data
  }
  throw new Error(
    `Mandatory fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getFileNameForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return meta.features.manage_pois.xlsx_template_coords.template
    case Poi.CustomPOIsImportMode.Geocode:
      return meta.features.manage_pois.xlsx_template_geocode.template
  }
  throw new Error(
    `File name not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getFieldsWithAtLeastOneFullfilledForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates:
      return []
    case Poi.CustomPOIsImportMode.Geocode: {
      const res =
        meta.features.manage_pois.xlsx_template_geocode
          .at_least_one_field_with_data_within
      if (!res) {
        throw new Error(
          `Fields not defined for type ${type}. Check metadata file property "xlsx_template_geocode.at_least_one_field_with_data_within" to verify the value is correct`,
        )
      }

      return res
    }
  }
  throw new Error(
    `Fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getOptionalFieldsForCustomPOIsTemplate(
  meta: Metadata.AppMetadata,
  type: Poi.CustomPOIsImportMode,
): string[] {
  switch (type) {
    case Poi.CustomPOIsImportMode.Coordinates: {
      const res = meta.features.manage_pois.xlsx_template_coords.optional_fields
      if (!res) {
        throw new Error(
          `Optional fields not defined for type ${type}. Check metadata file property "xlsx_template_coords.optional_fields" to verify the value is correct`,
        )
      }

      return res
    }
    case Poi.CustomPOIsImportMode.Geocode:
      return []
  }
  throw new Error(
    `Optional fields not found for type ${type}. Check metadata file to verify the value is correct`,
  )
}

export function getFieldsForCustomPOIsDownloadTemplate(
  meta: Metadata.AppMetadata,
): string[] {
  return meta.features.manage_pois.xlsx_header_download
}

export function showPackagePriceInSharePlan(
  meta: Metadata.AppMetadata,
): boolean {
  const packageProps = meta.features.share.package
  if (packageProps === undefined) {
    return false
  }
  return packageProps.showPrice
}

export function getBasemapsConfig(
  meta: Metadata.AppMetadata,
): null | Metadata.BasemapsOptions {
  const basemapConfig = meta.app_config.basemaps
  if (!basemapConfig.enable) {
    return null
  }
  return basemapConfig
}

export function getLayersPrecedence(meta: Metadata.AppMetadata): string[] {
  return meta.features.map.layers_precedence
}

export function isAudiencesQuintileEnabled(
  meta: Metadata.AppMetadata,
): boolean {
  return meta.features.audiences_quintile.enabled
}
